import * as React from 'react'

export interface ILoadingIcon extends React.ComponentPropsWithoutRef<'svg'> {
  iconColor?: string
}

export default function LoadingIcon({ iconColor = 'white', ...props }: ILoadingIcon) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.75C6.85617 2.75 2.75 6.89226 2.75 12C2.75 12.4142 2.41421 12.75 2 12.75C1.58579 12.75 1.25 12.4142 1.25 12C1.25 6.06774 6.02383 1.25 12 1.25C15.5577 1.25 18.2218 2.736 19.9817 4.20408C20.4731 4.61401 20.8957 5.02385 21.25 5.40201V2.56C21.25 2.14579 21.5858 1.81 22 1.81C22.4142 1.81 22.75 2.14579 22.75 2.56V7.56C22.75 7.97421 22.4142 8.31 22 8.31H17.56C17.1458 8.31 16.81 7.97421 16.81 7.56C16.81 7.14579 17.1458 6.81 17.56 6.81H20.4993C20.126 6.37739 19.6329 5.86656 19.0208 5.35592C17.4482 4.044 15.1123 2.75 12 2.75ZM22 11.25C22.4142 11.25 22.75 11.5858 22.75 12C22.75 17.9342 17.9342 22.75 12 22.75C8.97954 22.75 6.58449 21.2298 4.98113 19.7755C4.55554 19.3895 4.18095 19.004 3.86 18.6455V21.44C3.86 21.8542 3.52421 22.19 3.11 22.19C2.69579 22.19 2.36 21.8542 2.36 21.44V16.44C2.36 16.0258 2.69579 15.69 3.11 15.69H7.63C8.04421 15.69 8.38 16.0258 8.38 16.44C8.38 16.8542 8.04421 17.19 7.63 17.19H4.5871C4.94789 17.6286 5.41842 18.1471 5.98887 18.6645C7.45051 19.9902 9.50046 21.25 12 21.25C17.1058 21.25 21.25 17.1058 21.25 12C21.25 11.5858 21.5858 11.25 22 11.25Z"
        fill={iconColor}
      />
    </svg>
  )
}
